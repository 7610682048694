<template>
  <div class="color02 d-flex flex-column justify-content-center align-items-center p-5">


    <div class="d-flex justify-content-around align-items-center mb-5 w-100">
      <a class="align-self-center me-3 " href="#">
        <img src="/images/logo2.png" alt="" width="90" class="d-inline-block align-text-center" />
      </a>
      <div class="d-flex flex-column text-white">
        <span class="fs-3 fw-bold mb-3">送行者有限公司</span>
        <span class="mb-3">台北市農安街255號</span>
        <span @click="tel(1)" class="fw-bold fs-6 fs-md-1 me-5 text-white mb-3">0916-811-006 李小姐</span>
        <span @click="tel(2)" class="fw-bold fs-6 me-5 text-white mb-3">0915-099-618 胡先生</span>
        <img style="width:150px" src="/images/qrcode.jpg" alt="">
      </div>
    </div>
    <span class="text-white">©2021 Eternity. All rights reserved. </span>

  </div>

</template>

<script>
  export default {
    methods: {
      tel(e) {
        switch (e) {
          case 1:
            location.href = 'tel:0916811006';
            break;
          case 2:
            location.href = 'tel:0915099618';
            break;
        }
      }
    },

  }
</script>