<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-primary">
    <div class="container-md">

      <a class="navbar-brand" href="/index.html">
        <img
          src="/Images/logo2.png"
          alt=""
          width="95"
          
          class="d-inline-block align-text-center"
        />
      </a>
      
      <h3 class="text-white d-md-none">送行者有限公司</h3>
      
      <button
        class="navbar-toggler "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon " ></span>
      </button>
     
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active text-white" aria-current="page" href="/about/about.html"
              >關於我們</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="/product/main.html">花藝訂購</a>
          </li>

          <li class="nav-item">
            <a class="nav-link text-white" href="/service/chinese.html">中式禮儀</a>
          </li>

          <li class="nav-item">
            <a class="nav-link text-white" href="/service/western.html">西式禮儀</a>
          </li>      
          
          <li class="nav-item">
            <a class="nav-link text-white" href="https://sie.mysie.com.tw/" target="_blank">MySIE</a>
          </li>      


          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-white"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              禮儀服務
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="/service/chinese.html">中式禮儀</a></li>
              <li><a class="dropdown-item" href="/service/western.html">西式禮儀</a></li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li><a class="dropdown-item" href="/product/main.html">奠禮花藝訂購</a></li>
            </ul>
          </li> -->


        </ul>

        <div class="d-flex align-items-center">
          <i class="fas fa-mobile-alt fs-3 me-3 text-white"></i>
          <span class="fw-bold fs-4 me-5 text-white">0916811006 李小姐</span>
          <i @click="call()"
            class="fas fa-shopping-cart fs-2 text-shopping-cart-icon-color"
          ></i>
        </div>
      </div>
    </div>
  </nav>

 

 
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    call(){
      alert('網頁功能籌備中..\n電洽：0916811006 李小姐 / 0915099618 胡先生');
    }
  },
};
</script>
